<div class="__content  __content--main">
  <div
    fxLayout="row wrap"
    fxLayoutAlign="start start"
    fxLayoutGap="24px grid">

    <!-- graphs, settings and external  -->
    <div
      *ngIf="showSettings
        && ((graphsAndSettingsServices$ | async)?.length
          || (showCloudDns$ | async)
          || (showCdn$ | async)
          || (showCloudMail$ | async))"
      [fxFlex]="showFinancial ? settingsLayout : 100"
      class="__graphs-settings-external">

      <!-- graphs and settings -->
      <ng-container *ngIf="showGraphAndSettings$ | async">

        <ng-container *ngIf="(graphsAndSettingsServices$ | async) as graphsAndSettingsServices">
          <ng-container *ngIf="graphsAndSettingsServices.length">

            <h5 class="__title  c-button-title">
              <span class="c-button-title__text"
                translate="servicesCard.graphsAndSettings">
              </span>
            </h5>

            <ng-container *ngFor="let item of graphsAndSettingsServices; let last = last; let index = index; trackBy: _trackBy;">
              <ng-container [ngSwitch]="item.key">

                <!-- managed server -->
                <ng-container *ngSwitchCase="'ALL_MANAGED'">
                  <a
                    [routerLink]="[ '/servers-managed', 'managed-services' ]"
                    class="__service_main-item"
                    (click)="contentClicked.emit()">
                    <vshcz-service-item
                      [itemKey]="item.key">
                    </vshcz-service-item>
                  </a>
                </ng-container>

                <!-- managed server -->
                <ng-container *ngSwitchCase="'MANAGED_SERVER'">
                  <a
                    [routerLink]="[ '/servers-managed', 'list', 'managed-server' ]"
                    class="__service_main-item"
                    (click)="contentClicked.emit()">
                    <vshcz-service-item
                      [itemKey]="item.key">
                    </vshcz-service-item>
                  </a>
                </ng-container>

                <!-- m1 managed server -->
                <ng-container *ngSwitchCase="'M1_MANAGED_SERVER'">
                  <a
                    [routerLink]="[ '/servers-managed', 'list', 'managed-server' ]"
                    class="__service_main-item"
                    (click)="contentClicked.emit()">
                    <vshcz-service-item
                      [itemKey]="item.key">
                    </vshcz-service-item>
                  </a>
                </ng-container>

                <!-- m2 managed server -->
                <ng-container *ngSwitchCase="'M2_MANAGED_SERVER'">
                  <a
                    [routerLink]="[ '/servers-managed', 'list', 'managed-server' ]"
                    class="__service_main-item"
                    (click)="contentClicked.emit()">
                    <vshcz-service-item
                      [itemKey]="item.key">
                    </vshcz-service-item>
                  </a>
                </ng-container>

                <!-- managed windows server -->
                <ng-container *ngSwitchCase="'MANAGED_WINDOWS_SERVER'">
                  <a
                    [routerLink]="[ '/servers-managed', 'list', 'managed-server' ]"
                    class="__service_main-item"
                    (click)="contentClicked.emit()">
                    <vshcz-service-item
                      [itemKey]="item.key">
                    </vshcz-service-item>
                  </a>
                </ng-container>

                <!-- managed windows cluster -->
                <ng-container *ngSwitchCase="'MANAGED_WINDOWS_CLUSTER'">
                  <a
                    [routerLink]="[ '/servers-managed', 'list', 'managed-server' ]"
                    class="__service_main-item"
                    (click)="contentClicked.emit()">
                    <vshcz-service-item
                      [itemKey]="item.key">
                    </vshcz-service-item>
                  </a>
                </ng-container>

                <!-- managed windows cloud -->
                <ng-container *ngSwitchCase="'MANAGED_WINDOWS_CLOUD'">
                  <a
                    [routerLink]="[ '/servers-managed', 'list', 'managed-server' ]"
                    class="__service_main-item"
                    (click)="contentClicked.emit()">
                    <vshcz-service-item
                      [itemKey]="item.key">
                    </vshcz-service-item>
                  </a>
                </ng-container>

                <!-- currently grouped under ALL_MANAGED -->
                <!-- managed windows basic -->
                <ng-container *ngSwitchCase="'MANAGED_WINDOWS_BASIC'">
                  <a
                    [routerLink]="[ '/servers-managed', 'list', 'managed-server' ]"
                    class="__service_main-item"
                    (click)="contentClicked.emit()">
                    <vshcz-service-item
                      [itemKey]="item.key">
                    </vshcz-service-item>
                  </a>
                </ng-container>

                <!-- currently grouped under ALL_MANAGED -->
                <!-- cloud managed server -->
                <ng-container *ngSwitchCase="'CLOUD_MANAGED_SERVER'">
                  <a
                    [routerLink]="[ '/servers-managed', 'list', 'cloud-managed-server' ]"
                    class="__service_main-item"
                    (click)="contentClicked.emit()">
                    <vshcz-service-item
                      [itemKey]="item.key">
                    </vshcz-service-item>
                  </a>
                </ng-container>

                <!-- currently grouped under ALL_MANAGED -->
                <!-- managed cluster -->
                <ng-container *ngSwitchCase="'MANAGED_CLUSTER'">
                  <a
                    [routerLink]="[ '/servers-managed', 'list', 'managed-cluster' ]"
                    class="__service_main-item"
                    (click)="contentClicked.emit()">
                    <vshcz-service-item
                      [itemKey]="item.key">
                    </vshcz-service-item>
                  </a>
                </ng-container>

                <!-- server hosting -->
                <ng-container *ngSwitchCase="'SERVER_HOSTING'">
                  <a
                    [routerLink]="[ '/servers-hosted', 'list', 'server-hosting' ]"
                    class="__service_main-item"
                    (click)="contentClicked.emit()">
                    <vshcz-service-item
                      [itemKey]="item.key">
                    </vshcz-service-item>
                  </a>
                </ng-container>

                <!-- rack hosting -->
                <ng-container *ngSwitchCase="'RACK_HOSTING'">
                  <a
                    [routerLink]="[ '/racks' ]"
                    class="__service_main-item"
                    (click)="contentClicked.emit()">
                    <vshcz-service-item
                      [itemKey]="item.key">
                    </vshcz-service-item>
                  </a>
                </ng-container>

                <!-- dedicated servers -->
                <ng-container *ngSwitchCase="'DEDICATED_SERVER'">
                  <a
                    [routerLink]="[ '/servers-hosted', 'list', 'dedicated-server' ]"
                    class="__service_main-item"
                    (click)="contentClicked.emit()">

                    <vshcz-service-item
                      [itemKey]="item.key">
                    </vshcz-service-item>

                    <vshcz-kvm-badge class="__kvm-badge"></vshcz-kvm-badge>

                  </a>
                </ng-container>

                <!-- vds -->
                <ng-container *ngSwitchCase="'VDS'">
                  <a
                    [routerLink]="[ '/servers-hosted', 'list', 'vds' ]"
                    class="__service_main-item"
                    (click)="contentClicked.emit()">
                    <vshcz-service-item
                      [itemKey]="item.key">
                    </vshcz-service-item>
                  </a>
                </ng-container>

              </ng-container>
            </ng-container>

          </ng-container>
        </ng-container>

        <a
          *ngIf="showCloudDns$ | async"
          [routerLink]="[ '/domains' ]"
          class="__service_main-item"
          (click)="contentClicked.emit()">
          <vshcz-service-item
            [itemKey]="'CLOUD_DNS'">
          </vshcz-service-item>
        </a>

        <a
          *ngIf="(showCdn$ | async) && enableCDN"
          [href]="(cdnUrl$ | async) | safeUrl"
          target="_blank"
          class="__service_main-item"
          (click)="contentClicked.emit()">
          <vshcz-service-item
            [itemKey]="'CDN_APP'">
          </vshcz-service-item>
        </a>

        <a
          *ngIf="(showCloudMail$ | async) && enableCloudMail"
          [href]="(cloudMailUrl$ | async) | safeUrl"
          target="_blank"
          class="__service_main-item"
          (click)="contentClicked.emit()">
          <vshcz-service-item
            [itemKey]="'CLOUD_MAIL'">
          </vshcz-service-item>
        </a>

      </ng-container>

    </div>

    <!-- financial & status -->
    <div
      *ngIf="showFinancial"
      [fxFlex]="showSettings ? financialLayout : 100">

      <h5 class="__title  c-button-title  has-button">

        <span
          class="c-button-title__text"
          translate="servicesCard.financialAndStatus">
        </span>

        <a
          [routerLink]="[ '/', 'client-services' ]"
          mat-stroked-button
          color="accent"
          class="__link  c-button-title__link"
          [innerHTML]="'servicesCard.seeAll' | translate"
          (click)="contentClicked.emit()">
        </a>

      </h5>

      <ng-container *ngIf="(latestClientServices$ | async) as clientServices">
        <div
          *ngFor="let clientService of clientServices; let last = last; trackBy: _trackBy;"
          class="__client-service-item"
          [class.is-last]="last">
          <vshcz-client-service-item
            [name]="clientService.serviceName"
            [identification]="clientService.serviceIdentification"
            [activationDate]="clientService.activationDate"
            [serviceType]="clientService.serviceType"
            [oneTimePrice]="clientService.oneTimePrice"
            [monthPriceDiscounted]="clientService.monthPriceDiscounted"
            [billingPeriod]="clientService.billingPeriod"
            [currencyMap]="currencyMap$ | async"
            [currencyId]="clientService.currencyId"
            [languageId]="languageId$ | async"
            [clientZoneStatus]="clientService.clientZoneStatus">
          </vshcz-client-service-item>
        </div>
      </ng-container>

    </div>

  </div>
</div>


<div class="__content  __data-addition">

  <h5
    class="__data-additional-title"
    translate="servicesCard.lookingForNewService">
  </h5>

  <!-- Click to
  <a
    mat-stroked-button
    color="accent"
    class="__data-addition_button  __data-addition_raised-button">
    search and add a new service
  </a> or  -->

  <span translate="servicesCard.press"></span>

  <button
    mat-mini-fab
    class="__support-button mat-elevation-z1  __data-addition_button  __data-addition_fab-button"
    (click)="onContactSupportForNewService$.next(); contentClicked.emit()">
    <mat-icon class="__data-addition_fab-button_icon">contact_support</mat-icon>
  </button>

  <span [innerHTML]="'servicesCard.toTalk' | translate"></span>

</div>
