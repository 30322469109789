<a
  class="__link"
  tabindex="-1"
  (click)="onClick()"
  [routerLink]="dataWithLink?._link">

  <ng-container [ngSwitch]="dataWithLink.entity">

    <!-- user -->
    <ng-container *ngSwitchCase="entities.User">
      <div
        fxLayout
        fxLayoutAlign="start center">

        <div fxFlex="40px">
          <!-- avatar -->
          <vshcz-avatar
            [size]="32"
            class="__avatar"
            [mail]="dataWithLink?._searchContent['searchColumn02'].value | htmlEntities"
            [source]="'gravatar'">
          </vshcz-avatar>
        </div>

        <div fxFlex>

          <div class="__main-row">

            <!-- name highlight -->
            <span
              *ngIf="dataWithLink?._highlight['searchColumn01']"
              [innerHTML]="dataWithLink?._highlight['searchColumn01'].value">
            </span>

            <!-- name without highlight -->
            <span *ngIf="!dataWithLink?._highlight['searchColumn01']">
              {{ dataWithLink?._searchContent['searchColumn01'].value | htmlEntities }}
            </span>

          </div>

          <div class="__second-row">

            <!-- name highlight -->
            <span
              *ngIf="dataWithLink?._highlight['searchColumn02']"
              [innerHTML]="dataWithLink?._highlight['searchColumn02'].value">
            </span>

            <!-- name without highlight -->
            <span *ngIf="!dataWithLink?._highlight['searchColumn02']">
              {{ dataWithLink?._searchContent['searchColumn02'].value | htmlEntities }}
            </span>

          </div>

        </div>

        <div
          fxFlex="100px"
          class="u-text--right">
          <vshcz-entity-badge
            class="__entity-badge"
            [entity]="entityTranslations[dataWithLink?.entity]">
          </vshcz-entity-badge>
        </div>

      </div>
    </ng-container>

    <!-- ticket -->
    <ng-container *ngSwitchCase="entities.Ticket">
      <div
        fxLayout
        fxLayoutAlign="start center">

        <div fxFlex>

          <div class="__main-row">

            <!-- ticket code highlight -->
            <strong
              class="__ticket-code"
              *ngIf="dataWithLink?._highlight['searchColumn02']">
              <span [innerHTML]="dataWithLink?._highlight['searchColumn02'].value"></span>:
            </strong>

            <!-- ticket code without highlight -->
            <strong
              class="__ticket-code"
              *ngIf="!dataWithLink?._highlight['searchColumn02']">
              {{ dataWithLink?._searchContent['searchColumn02'].value | htmlEntities }}:
            </strong>

            <vshcz-ticket-state
              class="__ticket-state"
              stickerOnly
              [state]="dataWithLink?._infoContent['infoColumn01'].value">
            </vshcz-ticket-state>

            <!-- ticket title highlight -->
            <span
              *ngIf="dataWithLink?._highlight['searchColumn01']"
              [innerHTML]="dataWithLink?._highlight['searchColumn01'].value">
            </span>

            <!-- ticlet title without highlight -->
            <span *ngIf="!dataWithLink?._highlight['searchColumn01']">
              {{ dataWithLink?._searchContent['searchColumn01'].value | htmlEntities }}
            </span>

          </div>

          <div
            *ngIf="dataWithLink?._highlight['searchColumn03']"
            class="__second-row">
            <!-- ticket text highlight -->
            <span [innerHTML]="dataWithLink?._highlight['searchColumn03'].value"></span>
          </div>

        </div>

        <div
          fxFlex="100px"
          class="u-text--right">
          <vshcz-entity-badge
            class="__entity-badge"
            [entity]="entityTranslations[dataWithLink?.entity]">
          </vshcz-entity-badge>
        </div>

      </div>
    </ng-container>

    <!-- server park access -->
    <ng-container *ngSwitchCase="entities.ServerParkAccess">
      <div
        fxLayout
        fxLayoutAlign="start center">

        <div fxFlex>

          <div class="__main-row">

            <!-- server park name highlight -->
            <span
              *ngIf="dataWithLink?._highlight['searchColumn01']"
              [innerHTML]="dataWithLink?._highlight['searchColumn01'].value">
            </span>

            <!-- server park name without highlight -->
            <span *ngIf="!dataWithLink?._highlight['searchColumn01']">
              {{ dataWithLink?._searchContent['searchColumn01'].value | htmlEntities }}
            </span>

          </div>

          <div
            *ngIf="dataWithLink?._highlight['searchColumn02']"
            class="__second-row">

            <!-- server park name highlight -->
            <span
              *ngIf="dataWithLink?._highlight['searchColumn02']"
              [innerHTML]="dataWithLink?._highlight['searchColumn02'].value">
            </span>

            <!-- server park name without highlight -->
            <span *ngIf="!dataWithLink?._highlight['searchColumn02']">
              {{ dataWithLink?._searchContent['searchColumn02'].value | htmlEntities }}
            </span>

          </div>

        </div>

        <div
          fxFlex="190px"
          class="u-text--right">
          <vshcz-entity-badge
            class="__entity-badge"
            [entity]="entityTranslations[dataWithLink?.entity]">
          </vshcz-entity-badge>
        </div>

      </div>
    </ng-container>

    <!-- domain -->
    <ng-container *ngSwitchCase="entities.Domain">
      <div
        fxLayout
        fxLayoutAlign="start center">

        <div fxFlex>

          <div class="__main-row">

            <vshcz-domain-status
              *ngIf="dataWithLink?._highlight['searchColumn03']"
              class="__badge"
              [status]="dataWithLink?._highlight['searchColumn03'].value">
            </vshcz-domain-status>

            <!-- domain name highlight -->
            <span
              *ngIf="dataWithLink?._highlight['searchColumn01']"
              [innerHTML]="dataWithLink?._highlight['searchColumn01'].value">
            </span>

            <!-- domain name without highlight -->
            <span *ngIf="!dataWithLink?._highlight['searchColumn01']">
              {{ dataWithLink?._searchContent['searchColumn01'].value | htmlEntities }}
            </span>

          </div>

        </div>

        <div
          fxFlex="100px"
          class="u-text--right">
          <vshcz-entity-badge
            class="__entity-badge"
            [entity]="entityTranslations[dataWithLink?.entity]">
          </vshcz-entity-badge>
        </div>

      </div>
    </ng-container>

    <!-- invoice -->
    <ng-container *ngSwitchCase="entities.Invoice">
      <div
        fxLayout
        fxLayoutAlign="start center">

        <div fxFlex>

          <div class="__main-row">

            <!-- server park name highlight -->
            <span
              *ngIf="dataWithLink?._highlight['searchColumn01']"
              [innerHTML]="dataWithLink?._highlight['searchColumn01'].value">
            </span>

            <!-- server park name without highlight -->
            <span *ngIf="!dataWithLink?._highlight['searchColumn01']">
              {{ dataWithLink?._searchContent['searchColumn01'].value | htmlEntities }}
            </span>

          </div>

          <div class="__second-row">
            <vshcz-price
              [amount]="dataWithLink?._infoContent['infoColumn01'].value"
              [priceSize]="14"
              [symbolSize]="12"
              [currencyId]="dataWithLink?._infoContent['infoColumn02'].value"
              [currencyMap]="currencyMap">
            </vshcz-price>
          </div>
        </div>

        <div
          fxFlex="100px"
          class="u-text--right">
          <vshcz-entity-badge
            class="__entity-badge"
            [entity]="entityTranslations[dataWithLink?.entity]">
          </vshcz-entity-badge>
        </div>

      </div>
    </ng-container>

    <!-- servers -->
    <ng-container *ngSwitchCase="(dataWithLink.entity === entities.CloudManagedServer
      || dataWithLink.entity === entities.ManagedServer
      || dataWithLink.entity === entities.ManagedCluster
      || dataWithLink.entity === entities.M1ManagedServer
      || dataWithLink.entity === entities.M1ManagedCluster
      || dataWithLink.entity === entities.M2ManagedServer
      || dataWithLink.entity === entities.M2ManagedCluster
      || dataWithLink.entity === entities.windowsManagedServer
      || dataWithLink.entity === entities.windowsManagedCluster
      || dataWithLink.entity === entities.windowsManagedCloud
      || dataWithLink.entity === entities.windowsManagedBasic
      || dataWithLink.entity === entities.ServerHosting
      || dataWithLink.entity === entities.RackHosting
      || dataWithLink.entity === entities.DedicatedServer
      || dataWithLink.entity === entities.Vds)
        ? dataWithLink.entity
        : ''">
      <div
        fxLayout
        fxLayoutAlign="start center">
        <div fxFlex>

          <div class="__main-row">

            <!-- hostname -->
            <strong
              *ngIf="dataWithLink?._highlight['searchColumn01']"
              [innerHTML]="dataWithLink?._highlight['searchColumn01'].value">
            </strong>

            <!-- hostname without highlight -->
            <strong *ngIf="!dataWithLink?._highlight['searchColumn01']">
              {{ dataWithLink?._searchContent['searchColumn01'].value | htmlEntities }}
            </strong>

            <!-- note -->
            <strong
              *ngIf="dataWithLink?._highlight['searchColumn03']"
              [innerHTML]="dataWithLink?._highlight['searchColumn03'].value">
            </strong>

            <!-- note without highlight -->
            <strong *ngIf="!dataWithLink?._highlight['searchColumn03'] && dataWithLink?._searchContent['searchColumn03']">
              {{ dataWithLink?._searchContent['searchColumn03'].value | htmlEntities }}
            </strong>

          </div>

          <div
            *ngIf="dataWithLink?._searchContent['searchColumn02']"
            class="__second-row">
            #

            <!-- Device number -->
            <span
              *ngIf="dataWithLink?._highlight['searchColumn02']"
              [innerHTML]="dataWithLink?._highlight['searchColumn02'].value">
            </span>

            <!-- Device number without higlight -->
            <span *ngIf="!dataWithLink?._highlight['searchColumn02']">
              {{ dataWithLink?._searchContent['searchColumn02'].value | htmlEntities }}
            </span>

          </div>

        </div>

        <div
          fxFlex="210px"
          class="u-text--right">
          <vshcz-entity-badge
            class="__entity-badge"
            [entity]="entityTranslations[dataWithLink?.entity]">
          </vshcz-entity-badge>
        </div>

      </div>
    </ng-container>

    <!-- other -->
    <ng-container *ngSwitchDefault>
      <div class="__main-row">

        {{ dataWithLink?._searchContent['searchColumn01'].value }}

        <vshcz-entity-badge
          class="__entity-badge"
          [entity]="entityTranslations[dataWithLink?.entity]">
        </vshcz-entity-badge>

      </div>
    </ng-container>

  </ng-container>

</a>
