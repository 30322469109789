import * as tslib_1 from "tslib";
import { QueryList, AfterViewInit } from '@angular/core';
import { FocusKeyManager } from '@angular/cdk/a11y';
import { DOWN_ARROW, UP_ARROW, ENTER } from '@angular/cdk/keycodes';
import { NgxHotkeysService } from '@balticcode/ngx-hotkeys';
import { BaseClass } from '@zerops/fe/core';
import { TranslateService } from '@ngx-translate/core';
import { Store, select } from '@ngrx/store';
import { progressesByKeys, progressByKey } from '@zerops/fe/ngrx';
import { merge, Subject, BehaviorSubject, combineLatest } from 'rxjs';
import orderBy from 'lodash-es/orderBy';
import { takeUntil, map, withLatestFrom, filter, first, distinctUntilChanged, delay, debounceTime } from 'rxjs/operators';
import { Go } from '@app/common/ngrx-router';
import { currencyMap } from '@app/common/settings';
import { SearchItemComponent } from './modules';
import { SearchRequest, Open, Close, ActionTypes } from './search.action';
import { SearchModes, SearchEntities } from './search.constant';
import { suggestResults, searchResults, searchFormState, mode, open } from './search.selector';
import { searchLinkGenerator } from './search.utils';
var SearchContainer = /** @class */ (function (_super) {
    tslib_1.__extends(SearchContainer, _super);
    function SearchContainer(_store, _hotkeysService, _translate) {
        var _this = _super.call(this) || this;
        _this._store = _store;
        _this._hotkeysService = _hotkeysService;
        _this._translate = _translate;
        // # Form States
        _this.formState$ = _this._store.pipe(select(searchFormState));
        // # Event Streams
        _this.onSearch$ = new Subject();
        _this.onSearchOpen$ = new Subject();
        _this.onSearchClose$ = new Subject();
        _this.onSuggestSelected$ = new Subject();
        // # Data
        // -- sync
        _this.modes = SearchModes;
        _this.searchRequestKey = ActionTypes.SearchRequest;
        _this.suggestRequestKey = ActionTypes.SuggestRequest;
        _this.displayedSearchColumns = ['title', 'subtitle', 'additionalInfo', 'entity'];
        _this.defaultSearchSort = { active: 'score', direction: 'desc' };
        // -- async
        _this.sort$ = new BehaviorSubject(_this.defaultSearchSort);
        _this.mode$ = _this._store.pipe(select(mode));
        _this.open$ = _this._store.pipe(select(open));
        _this.currencyMap$ = _this._store.pipe(select(currencyMap));
        _this.suggests$ = _this._store.pipe(select(suggestResults));
        _this.keyword$ = _this.formState$.pipe((map(function (s) { return s.value.keyword; })));
        _this.keywordFilled$ = _this.formState$.pipe(map(function (s) { return s && s.value && s.value.keyword
            ? !!s.value.keyword.length
            : false; }));
        _this.searches$ = combineLatest(_this.sort$, _this._store.pipe(select(searchResults), filter(function (res) { return !!res; }), map(function (res) { return res.map(function (itm) { return (tslib_1.__assign({}, itm, { _link: searchLinkGenerator(itm.entity, itm.entityId) })); }); })))
            .pipe(map(function (_a) {
            var sort = _a[0], data = _a[1];
            if (!sort.active || sort.direction === '') {
                sort = _this.defaultSearchSort;
            }
            return orderBy(data, [sort.active], [sort.direction]);
        }));
        _this.suggestEmptyStateShown$ = combineLatest(_this._store.pipe(select(progressByKey(_this.suggestRequestKey)), map(function (p) { return !!p; }), distinctUntilChanged()), _this.suggests$.pipe(map(function (r) { return !!(r && r.length); }), distinctUntilChanged()), _this.keywordFilled$.pipe(delay(100))).pipe(map(function (_a) {
            var progress = _a[0], results = _a[1], hasKeyword = _a[2];
            return !progress && !results && hasKeyword;
        }), distinctUntilChanged());
        _this.searchEmptyStateShown$ = combineLatest(_this._store.pipe(select(progressByKey(_this.searchRequestKey)), map(function (p) { return !!p; }), distinctUntilChanged()), _this.searches$.pipe(map(function (r) { return !!(r && r.length); }), distinctUntilChanged())).pipe(map(function (_a) {
            var progress = _a[0], results = _a[1];
            return !progress && !results;
        }), distinctUntilChanged(), debounceTime(200));
        _this.searchNotRunning$ = _this._store.pipe(select(progressesByKeys([
            _this.searchRequestKey,
            _this.suggestRequestKey
        ])), map(function (keys) { return !keys.length; }));
        // # Action Streams
        _this._searchAction$ = _this.onSearch$.pipe(withLatestFrom(_this.formState$.pipe(map(function (s) { return s.value.keyword; }))), map(function (_a) {
            var _ = _a[0], keyword = _a[1];
            return new SearchRequest(keyword);
        }));
        _this._searchOpenAction$ = _this.onSearchOpen$.pipe(map(function () { return new Open(); }));
        _this._searchCloseAction$ = _this.onSearchClose$.pipe(withLatestFrom(_this.open$), filter(function (_a) {
            var _ = _a[0], isOpen = _a[1];
            return isOpen;
        }), map(function () { return new Close(); }));
        _this._suggestSelected$ = _this.onSuggestSelected$.pipe(map(function (path) { return new Go({ path: path }); }));
        // prepare entity translation object so each item doesn't
        // have to subscribe on its own
        _this._translate
            .get([
            "search.entities." + SearchEntities.User,
            "search.entities." + SearchEntities.ServerParkAccess,
            "search.entities." + SearchEntities.Ticket,
            "search.entities." + SearchEntities.Invoice,
            "search.entities." + SearchEntities.CloudManagedServer,
            "search.entities." + SearchEntities.ManagedCluster,
            "search.entities." + SearchEntities.ManagedServer,
            "search.entities." + SearchEntities.windowsManagedServer,
            "search.entities." + SearchEntities.windowsManagedCluster,
            "search.entities." + SearchEntities.windowsManagedBasic,
            "search.entities." + SearchEntities.windowsManagedCloud,
            "search.entities." + SearchEntities.ServerHosting,
            "search.entities." + SearchEntities.RackHosting,
            "search.entities." + SearchEntities.DedicatedServer,
            "search.entities." + SearchEntities.Vds,
            "search.entities." + SearchEntities.Domain,
            "search.entities." + SearchEntities.M2ManagedServer,
            "search.entities." + SearchEntities.M2ManagedCluster
        ])
            .pipe(first(), map(function (translations) {
            var _a;
            return (_a = {},
                _a[SearchEntities.User] = translations["search.entities." + SearchEntities.User],
                _a[SearchEntities.ServerParkAccess] = translations["search.entities." + SearchEntities.ServerParkAccess],
                _a[SearchEntities.Ticket] = translations["search.entities." + SearchEntities.Ticket],
                _a[SearchEntities.Invoice] = translations["search.entities." + SearchEntities.Invoice],
                _a[SearchEntities.CloudManagedServer] = translations["search.entities." + SearchEntities.CloudManagedServer],
                _a[SearchEntities.ManagedCluster] = translations["search.entities." + SearchEntities.ManagedCluster],
                _a[SearchEntities.ManagedServer] = translations["search.entities." + SearchEntities.ManagedServer],
                _a[SearchEntities.windowsManagedServer] = translations["search.entities." + SearchEntities.windowsManagedServer],
                _a[SearchEntities.windowsManagedCluster] = translations["search.entities." + SearchEntities.windowsManagedCluster],
                _a[SearchEntities.windowsManagedBasic] = translations["search.entities." + SearchEntities.windowsManagedBasic],
                _a[SearchEntities.windowsManagedCloud] = translations["search.entities." + SearchEntities.windowsManagedCloud],
                _a[SearchEntities.ServerHosting] = translations["search.entities." + SearchEntities.ServerHosting],
                _a[SearchEntities.RackHosting] = translations["search.entities." + SearchEntities.RackHosting],
                _a[SearchEntities.DedicatedServer] = translations["search.entities." + SearchEntities.DedicatedServer],
                _a[SearchEntities.Vds] = translations["search.entities." + SearchEntities.Vds],
                _a[SearchEntities.Domain] = translations["search.entities." + SearchEntities.Domain],
                _a[SearchEntities.M2ManagedServer] = translations["search.entities." + SearchEntities.M2ManagedServer],
                _a[SearchEntities.M2ManagedCluster] = translations["search.entities." + SearchEntities.M2ManagedCluster],
                _a);
        }))
            .subscribe(function (translations) { return _this.entitiesTranslations = translations; });
        // open hotkey
        _this._hotkeysService.register({
            combo: 'alt+f',
            handler: function () {
                _this.onSearchOpen$.next();
                return false;
            }
        });
        // # Store Dispatcher
        merge(_this._searchAction$, _this._searchOpenAction$, _this._searchCloseAction$, _this._suggestSelected$)
            .pipe(takeUntil(_this._ngOnDestroy$))
            .subscribe(_this._store);
        return _this;
    }
    SearchContainer.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.keyManager = new FocusKeyManager(this.searchItems).withWrap();
        // reset when new search happens
        this.suggests$
            .pipe(takeUntil(this._ngOnDestroy$))
            .subscribe(function () {
            _this.keyManager.updateActiveItem(undefined);
            _this.activeIndex = undefined;
        });
        this.keyManager
            .change
            .pipe(takeUntil(this._ngOnDestroy$))
            .subscribe(function (index) { return _this.activeIndex = index; });
    };
    SearchContainer.prototype.onDocumentKeydownEsc = function () {
        this.onSearchClose$.next();
    };
    SearchContainer.prototype.onKeyUp = function (event) {
        event.stopImmediatePropagation();
        if (this.keyManager) {
            var keyCode = event.keyCode;
            var len = this.searchItems.length;
            var activeIndex = this.keyManager.activeItemIndex;
            // we are on the last item and going down
            // or we are on the first item and going up
            // reset selected item
            if (keyCode === DOWN_ARROW && activeIndex === (len - 1)
                || keyCode === UP_ARROW && activeIndex === 0) {
                this.keyManager.updateActiveItem(undefined);
                this.activeIndex = undefined;
                return false;
            }
            if (keyCode === DOWN_ARROW || keyCode === UP_ARROW) {
                this.keyManager.onKeydown(event);
                return false;
            }
            if (keyCode === ENTER) {
                if (this.activeIndex !== undefined) {
                    this.onSuggestSelected$.next(this.keyManager.activeItem.dataWithLink._link);
                    this.onSearchClose$.next();
                }
                else {
                    this.onSearch$.next();
                }
                return false;
            }
        }
    };
    SearchContainer.prototype.onKeyDown = function (event) {
        if (event.keyCode === DOWN_ARROW || event.keyCode === UP_ARROW) {
            event.preventDefault();
        }
    };
    SearchContainer.prototype.onBlur = function () {
        this.focused = false;
        this.keyManager.updateActiveItem(undefined);
    };
    SearchContainer.prototype.onFocus = function () {
        this.focused = true;
        if (this.keyManager) {
            this.keyManager.setFirstItemActive();
        }
    };
    SearchContainer.prototype.trackBy = function (index) {
        return index;
    };
    return SearchContainer;
}(BaseClass));
export { SearchContainer };
