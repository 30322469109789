import {
  Component,
  ViewChildren,
  QueryList,
  AfterViewInit,
  HostListener
} from '@angular/core';
import {
  ListKeyManager,
  FocusKeyManager,
  FocusableOption
} from '@angular/cdk/a11y';
import { Sort } from '@angular/material/sort';
import { DOWN_ARROW, UP_ARROW, ENTER } from '@angular/cdk/keycodes';
import { NgxHotkeysService } from '@balticcode/ngx-hotkeys';
import { BaseClass } from '@zerops/fe/core';
import { TranslateService } from '@ngx-translate/core';
import { Store, select } from '@ngrx/store';
import { progressesByKeys, progressByKey } from '@zerops/fe/ngrx';
import { merge, Subject, BehaviorSubject, combineLatest } from 'rxjs';
import orderBy from 'lodash-es/orderBy';
import {
  takeUntil,
  map,
  withLatestFrom,
  filter,
  first,
  distinctUntilChanged,
  delay,
  debounceTime
} from 'rxjs/operators';
import { State } from '@app/models';
import { Go } from '@app/common/ngrx-router';
import { currencyMap, Currency } from '@app/common/settings';
import { HashMap } from 'utils';
import { SearchItemComponent } from './modules';
import { SearchRequest, Open, Close, ActionTypes } from './search.action';
import { SearchModes, SearchEntities } from './search.constant';
import {
  suggestResults,
  searchResults,
  searchFormState,
  mode,
  open
} from './search.selector';
import { searchAnimation } from './search.animations';
import { searchLinkGenerator } from './search.utils';

@Component({
  selector: 'vshcz-search',
  templateUrl: './search.container.html',
  styleUrls: [ './search.container.scss' ],
  animations: [ searchAnimation ]
})
export class SearchContainer extends BaseClass implements AfterViewInit {
  // # Form States
  formState$ = this._store.pipe(select(searchFormState));

  // # Event Streams
  onSearch$ = new Subject<string>();
  onSearchOpen$ = new Subject<void>();
  onSearchClose$ = new Subject<void>();
  onSuggestSelected$ = new Subject<any[]>();

  // # Data
  // -- sync
  modes = SearchModes;
  activeIndex: number;
  keyManager: ListKeyManager<SearchItemComponent>;
  focused: boolean;
  currencyMap: HashMap<Currency>;
  searchRequestKey = ActionTypes.SearchRequest;
  suggestRequestKey = ActionTypes.SuggestRequest;
  entitiesTranslations: { [key: string]: string; };
  displayedSearchColumns = [ 'title', 'subtitle', 'additionalInfo', 'entity' ];
  defaultSearchSort: Sort = { active: 'score', direction: 'desc' };

  // -- angular
  @ViewChildren(SearchItemComponent)
  searchItems: QueryList<FocusableOption & SearchItemComponent>;

  // -- async
  sort$ = new BehaviorSubject<Sort>(this.defaultSearchSort);
  mode$ = this._store.pipe(select(mode));
  open$ = this._store.pipe(select(open));
  currencyMap$ = this._store.pipe(select(currencyMap));
  suggests$ = this._store.pipe(select(suggestResults));
  keyword$ = this.formState$.pipe((map((s) => s.value.keyword)));
  keywordFilled$ = this.formState$.pipe(map((s) => s && s.value && s.value.keyword
    ? !!s.value.keyword.length
    : false
  ));
  searches$ = combineLatest(
    this.sort$,
    this._store.pipe(
      select(searchResults),
      filter((res) => !!res),
      map((res) => res.map((itm) => ({
        ...itm,
        _link: searchLinkGenerator(itm.entity, itm.entityId)
      })))
    ))
    .pipe(map(([ sort, data ]) => {
      if (!sort.active || sort.direction === '') {
        sort = this.defaultSearchSort;
      }
      return orderBy(data, [ sort.active ], [ sort.direction as 'asc' | 'desc' ]);
    }));
  suggestEmptyStateShown$ = combineLatest(
    this._store.pipe(
      select(progressByKey(this.suggestRequestKey)),
      map((p) => !!p),
      distinctUntilChanged()
    ),
    this.suggests$.pipe(
      map((r) => !!(r && r.length)),
      distinctUntilChanged()
    ),
    this.keywordFilled$.pipe(delay(100))
  ).pipe(
    map(([ progress, results, hasKeyword ]) => !progress && !results && hasKeyword),
    distinctUntilChanged(),
  );
  searchEmptyStateShown$ = combineLatest(
    this._store.pipe(
      select(progressByKey(this.searchRequestKey)),
      map((p) => !!p),
      distinctUntilChanged(),
    ),
    this.searches$.pipe(
      map((r) => !!(r && r.length)),
      distinctUntilChanged()
    )
  ).pipe(
    map(([ progress, results ]) => !progress && !results),
    distinctUntilChanged(),
    debounceTime(200)
  );
  searchNotRunning$ = this._store.pipe(
    select(progressesByKeys([
      this.searchRequestKey,
      this.suggestRequestKey
    ])),
    map((keys) => !keys.length)
  );

  // # Action Streams
  private _searchAction$ = this.onSearch$.pipe(
    withLatestFrom(this.formState$.pipe(map((s) => s.value.keyword))),
    map(([ _, keyword ]) => new SearchRequest(keyword))
  );
  private _searchOpenAction$ = this.onSearchOpen$.pipe(
    map(() => new Open())
  );
  private _searchCloseAction$ = this.onSearchClose$.pipe(
    withLatestFrom(this.open$),
    filter(([ _, isOpen]) => isOpen),
    map(() => new Close())
  );
  private _suggestSelected$ = this.onSuggestSelected$.pipe(
    map((path) => new Go({ path }))
  );

  constructor(
    private _store: Store<State>,
    private _hotkeysService: NgxHotkeysService,
    private _translate: TranslateService
  ) {
    super();

    // prepare entity translation object so each item doesn't
    // have to subscribe on its own
    this._translate
      .get([
        `search.entities.${SearchEntities.User}`,
        `search.entities.${SearchEntities.ServerParkAccess}`,
        `search.entities.${SearchEntities.Ticket}`,
        `search.entities.${SearchEntities.Invoice}`,
        `search.entities.${SearchEntities.CloudManagedServer}`,
        `search.entities.${SearchEntities.ManagedCluster}`,
        `search.entities.${SearchEntities.ManagedServer}`,
        `search.entities.${SearchEntities.windowsManagedServer}`,
        `search.entities.${SearchEntities.windowsManagedCluster}`,
        `search.entities.${SearchEntities.windowsManagedBasic}`,
        `search.entities.${SearchEntities.windowsManagedCloud}`,
        `search.entities.${SearchEntities.ServerHosting}`,
        `search.entities.${SearchEntities.RackHosting}`,
        `search.entities.${SearchEntities.DedicatedServer}`,
        `search.entities.${SearchEntities.Vds}`,
        `search.entities.${SearchEntities.Domain}`,
        `search.entities.${SearchEntities.M2ManagedServer}`,
        `search.entities.${SearchEntities.M2ManagedCluster}`
      ])
      .pipe(
        first(),
        map((translations) => ({
          [SearchEntities.User]: translations[`search.entities.${SearchEntities.User}`],
          [SearchEntities.ServerParkAccess]: translations[`search.entities.${SearchEntities.ServerParkAccess}`],
          [SearchEntities.Ticket]: translations[`search.entities.${SearchEntities.Ticket}`],
          [SearchEntities.Invoice]: translations[`search.entities.${SearchEntities.Invoice}`],
          [SearchEntities.CloudManagedServer]: translations[`search.entities.${SearchEntities.CloudManagedServer}`],
          [SearchEntities.ManagedCluster]: translations[`search.entities.${SearchEntities.ManagedCluster}`],
          [SearchEntities.ManagedServer]: translations[`search.entities.${SearchEntities.ManagedServer}`],
          [SearchEntities.windowsManagedServer]: translations[`search.entities.${SearchEntities.windowsManagedServer}`],
          [SearchEntities.windowsManagedCluster]: translations[`search.entities.${SearchEntities.windowsManagedCluster}`],
          [SearchEntities.windowsManagedBasic]: translations[`search.entities.${SearchEntities.windowsManagedBasic}`],
          [SearchEntities.windowsManagedCloud]: translations[`search.entities.${SearchEntities.windowsManagedCloud}`],
          [SearchEntities.ServerHosting]: translations[`search.entities.${SearchEntities.ServerHosting}`],
          [SearchEntities.RackHosting]: translations[`search.entities.${SearchEntities.RackHosting}`],
          [SearchEntities.DedicatedServer]: translations[`search.entities.${SearchEntities.DedicatedServer}`],
          [SearchEntities.Vds]: translations[`search.entities.${SearchEntities.Vds}`],
          [SearchEntities.Domain]: translations[`search.entities.${SearchEntities.Domain}`],
          [SearchEntities.M2ManagedServer]: translations[`search.entities.${SearchEntities.M2ManagedServer}`],
          [SearchEntities.M2ManagedCluster]: translations[`search.entities.${SearchEntities.M2ManagedCluster}`]
        }))
      )
      .subscribe((translations) => this.entitiesTranslations = translations);

    // open hotkey
    this._hotkeysService.register({
      combo: 'alt+f',
      handler: () => {
        this.onSearchOpen$.next();
        return false;
      }
    });

    // # Store Dispatcher
    merge(
      this._searchAction$,
      this._searchOpenAction$,
      this._searchCloseAction$,
      this._suggestSelected$
    )
      .pipe(takeUntil(this._ngOnDestroy$))
      .subscribe(this._store);

  }

  ngAfterViewInit() {
    this.keyManager = new FocusKeyManager(this.searchItems).withWrap();

    // reset when new search happens
    this.suggests$
      .pipe(takeUntil(this._ngOnDestroy$))
      .subscribe(() => {
        this.keyManager.updateActiveItem(undefined);
        this.activeIndex = undefined;
      });

    this.keyManager
      .change
      .pipe(takeUntil(this._ngOnDestroy$))
      .subscribe((index) => this.activeIndex = index);
  }

  @HostListener('document:keydown.esc')
  onDocumentKeydownEsc() {
    this.onSearchClose$.next();
  }

  onKeyUp(event: KeyboardEvent) {
    event.stopImmediatePropagation();

    if (this.keyManager) {
      const { keyCode } = event;
      const len = this.searchItems.length;
      const activeIndex = this.keyManager.activeItemIndex;

      // we are on the last item and going down
      // or we are on the first item and going up
      // reset selected item
      if (keyCode === DOWN_ARROW && activeIndex ===  (len - 1)
        || keyCode === UP_ARROW && activeIndex === 0) {
        this.keyManager.updateActiveItem(undefined);
        this.activeIndex = undefined;
        return false;
      }

      if (keyCode === DOWN_ARROW || keyCode === UP_ARROW) {
        this.keyManager.onKeydown(event);
        return false;
      }

      if (keyCode === ENTER) {
        if (this.activeIndex !== undefined) {
          this.onSuggestSelected$.next(this.keyManager.activeItem.dataWithLink._link);
          this.onSearchClose$.next();
        } else {
          this.onSearch$.next();
        }
        return false;
      }

    }
  }

  onKeyDown(event: KeyboardEvent) {
    if (event.keyCode === DOWN_ARROW || event.keyCode === UP_ARROW) {
      event.preventDefault();
    }
  }

  onBlur() {
    this.focused = false;
    this.keyManager.updateActiveItem(undefined);
  }

  onFocus() {
    this.focused = true;

    if (this.keyManager) {
      this.keyManager.setFirstItemActive();
    }
  }

  trackBy(index: number) {
    return index;
  }
}
