import * as tslib_1 from "tslib";
import { ElementRef } from '@angular/core';
import { ObservableMedia } from '@angular/flex-layout';
import { ViewportRuler } from '@angular/cdk/scrolling';
import { Store, select } from '@ngrx/store';
import { BaseClass } from '@zerops/fe/core';
import { progressByKey } from '@zerops/fe/ngrx';
import { Subject, merge, BehaviorSubject, combineLatest } from 'rxjs';
import { map, takeUntil, filter, withLatestFrom, tap, startWith, distinctUntilChanged, switchMap } from 'rxjs/operators';
import * as uuidv4 from 'uuid/v4';
import { TicketDetailContainer } from '@app/common/ticket-detail';
import { TranslateService } from '@ngx-translate/core';
import { TicketsBaseMessageRequest, TicketsBaseUnlockRequest, ticketTopicListGrouped, ticketTopicList, TicketsBaseUpdateCopyListCheckRequest, TicketsBaseActionTypes, AttachedPriceOfferStates } from '@app/base/tickets-base';
import { authActiveUserNormalizedClient, authActiveClientUser, identity } from '@app/base/auth-base';
import { ticketAddFormValue, TicketAddModuleTokens } from '@app/common/ticket-add';
import { TicketStatuses } from '@app/common/tickets/tickets.constant';
import { ClientUserRoles } from '@app/base/client-user-base';
import { TicketsBaseUpdateCopyListRequest } from '@app/base/tickets-base';
import { activeUserClientId } from '@app/base/auth-base/auth-base.selector';
import { FilesEmptyQueue } from '@app/common/files';
import { state, detailData, open, ticketDetailTicketTextFormState } from './tickets-trigger.selector';
import { TicketsTriggerStates } from './tickets-trigger.constant';
import { SetState, Reset, Open, Close } from './tickets-trigger.action';
var TicketsTriggerContainer = /** @class */ (function (_super) {
    tslib_1.__extends(TicketsTriggerContainer, _super);
    function TicketsTriggerContainer(media, _store, _translate, _ruler) {
        var _this = _super.call(this) || this;
        _this.media = media;
        _this._store = _store;
        _this._translate = _translate;
        _this._ruler = _ruler;
        // # Form States
        _this.ticketUpdateTextFormState$ = _this._store.pipe(select(ticketDetailTicketTextFormState));
        // # Event Streams
        _this.onSetState$ = new Subject();
        _this.onSendMessage$ = new Subject();
        _this.onUnlock$ = new Subject();
        _this.onReset$ = new Subject();
        _this.onToggle$ = new Subject();
        _this.onClose$ = new Subject();
        _this.onCardHover$ = new Subject();
        _this.onToggleWatchTicket$ = new Subject();
        _this.headerHeight = 76;
        _this.detailMessageHeight = 74;
        _this.ticketRaitingHeight = 111;
        _this.ticketPriceOfferHeight = 102;
        _this.ticketRealizationDateHeight = 72;
        _this.states = TicketsTriggerStates;
        _this.ticketStatuses = TicketStatuses;
        _this.updateCopyListRequestKey = TicketsBaseActionTypes.UpdateCopyListRequest;
        _this.clientUserRoles = ClientUserRoles;
        _this.attachedPriceOfferStates = AttachedPriceOfferStates;
        // -- async
        _this.viewportHeight$ = _this._ruler
            .change(100)
            .pipe(map(function () { return _this._ruler.getViewportSize().height; }), startWith(_this._ruler.getViewportSize().height));
        _this.uploadIntanceId$ = new BehaviorSubject('');
        _this.currentLang$ = _this._translate.onLangChange.pipe(startWith(_this._translate.currentLang), map(function () { return _this._translate.currentLang; }));
        _this.state$ = _this._store.pipe(select(state), 
        // set new upload instance id every time user sets
        // state to `Detail`
        tap(function (s) {
            if (s === _this.states.Detail) {
                _this.uploadIntanceId$.next(uuidv4());
            }
        }));
        _this.height$ = combineLatest(_this.state$, _this.viewportHeight$).pipe(map(function (_a) {
            var curState = _a[0], height = _a[1];
            switch (curState) {
                case _this.states.Dashboard:
                    return 480;
                case _this.states.List:
                case _this.states.Detail:
                    return height - _this._defaultPopOffset;
                case _this.states.Add:
                    return 480 + _this.headerHeight;
                default:
                    console.warn('Unknown tickets trigger state');
                    return 600;
            }
        }));
        _this.open$ = _this._store.pipe(select(open));
        _this.detail$ = _this._store.pipe(select(detailData));
        _this.identity$ = _this._store.pipe(select(identity));
        _this.watchLoadingKey$ = _this.detail$.pipe(filter(function (data) { return !!data && !!data.id; }), map(function (data) { return _this.updateCopyListRequestKey + ":" + data.id + ":watch"; }));
        _this.watchLoading$ = _this.watchLoadingKey$.pipe(switchMap(function (key) { return _this._store.pipe(select(progressByKey(key))); }));
        _this.activeClientId$ = _this._store.pipe(select(activeUserClientId));
        _this.activeClientUser$ = _this._store.pipe(select(authActiveClientUser));
        _this.userIsWatchingTicket$ = _this.detail$.pipe(filter(function (d) { return !!d; }), map(function (d) { return ({ d: d, copyList: d.copyList || [] }); }), withLatestFrom(_this.identity$.pipe(filter(function (d) { return !!d; }), map(function (d) { return d.email; }))), map(function (_a) {
            var _b = _a[0], d = _b.d, copyList = _b.copyList, mail = _a[1];
            return d.assignedUserEmail === mail || copyList.includes(mail);
        }));
        _this.closeShown$ = _this.onCardHover$.pipe(distinctUntilChanged(), map(function (shown) { return shown && !_this.media.isActive('lt-md'); }));
        _this.ticketTopicsGroupsAuthorized$ = combineLatest(_this._store.pipe(select(ticketTopicListGrouped)), _this.activeClientUser$)
            .pipe(map(function (_a) {
            var groups = _a[0], user = _a[1];
            var hasServices = user && user.client && user.client.connectedServiceList
                ? !!user.client.connectedServiceList.find(function (service) { return service.clientZoneStatus === 'ACTIVE'; })
                : false;
            var grps = groups;
            if (!user || !grps || !grps.length) {
                return [];
            }
            // skip filtering if user is Technical and company has no services
            if (!hasServices && user.roleCode === ClientUserRoles.TechnicalUser) {
                return [];
            }
            // if company has no services, filter out technical group
            if (!hasServices) {
                grps = grps.filter(function (group) { return group.key !== 'TECHNICAL'; });
            }
            // if user is manager return all groups
            if (user.roleCode === ClientUserRoles.Manager) {
                return grps;
            }
            // if user is Financial user, return only groups for finance
            if (user.roleCode === ClientUserRoles.FinancialUser) {
                return grps.filter(function (group) { return group.key !== 'TECHNICAL'; });
            }
            // user must be Technical (and company has active services)
            // => return only groups for technical
            return grps.filter(function (group) { return group.key === 'TECHNICAL'; });
        }));
        _this.ticketTopicsGrouped$ = combineLatest(_this.currentLang$, _this.ticketTopicsGroupsAuthorized$).pipe(map(function (_a) {
            var lang = _a[0], groups = _a[1];
            return groups.map(function (group) { return (tslib_1.__assign({}, group, { 
                // add artificial `_name` property to each item
                // that contains name by current language
                items: group.items.map(function (itm) { return (tslib_1.__assign({}, itm, { _name: itm.name[lang] })); }) })); });
        }));
        _this.selectedTopicId$ = _this._store.pipe(select(ticketAddFormValue), map(function (values) { return values.ticketTopicId; }));
        _this.selectedAddTopic$ = combineLatest(_this.currentLang$, _this.selectedTopicId$, _this._store.pipe(select(ticketTopicList))).pipe(map(function (_a) {
            var lang = _a[0], topicId = _a[1], topics = _a[2];
            // find currently selected add topic and return name by current language
            if (!topicId) {
                return undefined;
            }
            var topic = topics.find((function (t) { return t.id === topicId; }));
            return topic ? topic.name[lang] : undefined;
        }));
        _this.activeClientSalesman$ = _this._store.pipe(select(authActiveUserNormalizedClient), filter(function (c) { return !!c; }), map(function (client) { return client.salesman; }));
        // # Action Streams
        _this._setStateAction$ = _this.onSetState$.pipe(map(function (data) { return new SetState(data); }));
        _this._unlockAction$ = _this.onUnlock$.pipe(withLatestFrom(_this.detail$), map(function (_a) {
            var _ = _a[0], detail = _a[1];
            return new TicketsBaseUnlockRequest(detail.id);
        }));
        _this._sendMessageAction$ = _this.onSendMessage$.pipe(withLatestFrom(_this.uploadIntanceId$, _this.ticketUpdateTextFormState$), filter(function (_a) {
            var _ = _a[0], __ = _a[1], isValid = _a[2].isValid;
            return isValid;
        }), map(function (_a) {
            var id = _a[0], instanceId = _a[1], value = _a[2].value;
            return new TicketsBaseMessageRequest({
                instanceId: instanceId,
                ticketId: id,
                messageText: value.ticketMessage
            });
        }), 
        // create a new temp instance id for next message
        tap(function () { return _this.uploadIntanceId$.next(uuidv4()); }));
        _this._resetAction$ = _this.onReset$.pipe(map(function () { return new Reset(); }));
        _this._toggleAction$ = _this.onToggle$.pipe(withLatestFrom(_this.open$), map(function (_a) {
            var _ = _a[0], isOpen = _a[1];
            if (isOpen) {
                return new Close();
            }
            else {
                return new Open();
            }
        }));
        _this._closeAction$ = _this.onClose$.pipe(withLatestFrom(_this.open$), filter(function (_a) {
            var _ = _a[0], isOpen = _a[1];
            return isOpen;
        }), map(function () { return new Close(); }));
        _this._toggleWatchTicketAction$ = _this.onToggleWatchTicket$.pipe(withLatestFrom(_this.identity$, _this.userIsWatchingTicket$, _this.detail$), map(function (_a) {
            var _ = _a[0], user = _a[1], userIsWatchingTicket = _a[2], detail = _a[3];
            if (!!user && !!detail) {
                if (userIsWatchingTicket) {
                    return new TicketsBaseUpdateCopyListRequest(detail.id, detail.copyList.filter(function (m) { return m !== user.email; }), user.email, 'remove', true);
                }
                else {
                    return new TicketsBaseUpdateCopyListCheckRequest(detail.id, detail.copyList.concat([user.email]), user.email, true);
                }
            }
        }));
        _this._resetFileQueueAction$ = _this.selectedTopicId$.pipe(distinctUntilChanged(), map(function () { return new FilesEmptyQueue(TicketAddModuleTokens.Name); }));
        _this._defaultPopOffset = 150;
        // # Data Resolvers
        _this.height$
            .pipe(takeUntil(_this._ngOnDestroy$))
            .subscribe(function (height) { return _this.height = height; });
        _this.detail$
            .pipe(takeUntil(_this._ngOnDestroy$))
            .subscribe(function (data) { return _this.detail = data; });
        // scroll to bottom of detail when we are on detail
        // and ticketmessages are loaded
        _this.detail$
            .pipe(filter(function (d) { return !!(d
            && d.ticketMessageList
            && d.ticketMessageList.length); }), takeUntil(_this._ngOnDestroy$))
            .subscribe(function (d) {
            // intentional to trigger immediately on new message add
            if (_this.ticketDetailRef) {
                _this.ticketDetailRef.scrollTo('bottom', 0);
            }
            setTimeout(function () {
                if (_this.ticketDetailRef) {
                    _this.ticketDetailRef.scrollTo('bottom', 0);
                }
            });
        });
        // # Store Dispatcher
        merge(_this._setStateAction$, _this._sendMessageAction$, _this._unlockAction$, _this._resetAction$, _this._toggleAction$, _this._closeAction$, _this._toggleWatchTicketAction$, _this._resetFileQueueAction$)
            .pipe(takeUntil(_this._ngOnDestroy$))
            .subscribe(_this._store);
        return _this;
    }
    Object.defineProperty(TicketsTriggerContainer.prototype, "classIsMobile", {
        get: function () {
            return this.media.isActive('lt-md');
        },
        enumerable: true,
        configurable: true
    });
    TicketsTriggerContainer.prototype.setSizes = function (taSize) {
        var _this = this;
        if (this.formRef) {
            setTimeout(function () {
                _this.detailMessageHeight = _this.formRef.nativeElement.clientHeight;
            });
        }
    };
    TicketsTriggerContainer.prototype.preventFocusRestore = function (popover) {
        popover['_previouslyFocusedElement'] = null;
    };
    TicketsTriggerContainer.prototype.onDocumentKeydownEsc = function () {
        this.onClose$.next();
    };
    return TicketsTriggerContainer;
}(BaseClass));
export { TicketsTriggerContainer };
