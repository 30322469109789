export enum Roles {
  // -- base
  Authorized = '[Roles] Authorized',
  Unauthorized = '[Roles] Unauthorized',
  Manager = '[Roles] Manager',
  Financial = '[Roles] Financial User',
  Technical = '[Roles] Technical User',

  // -- special
  ManagerWithSPAccess = '[Roles] Manager With Server Park Access',
  AuthorizedToSendTickets = '[Roles] Authorized To Send Tickets'
}

export enum ModuleTokens {
  Name = 'authBase'
}

export enum ExternalAuthTypes {
  MojeId = 'mojeid',
  Google = 'google'
}

export enum ExternalAuthApiTypes {
  MojeId = 'mojeId',
  Google = 'googleAccount'
}
