import { version } from './version';

export const environment = {
  production: true,
  envName: 'hu',
  apiUrl: '',
  wsUrl: 'cdn.hu.dev.kube.vsh-labs.cz',
  cloudDnsWsUrl: 'client.hu.dev.kube.vsh-labs.cz',
  googleSiteKey: 'undefined',
  cdnAuthRedirectUrl: 'https://cdn.hu.dev.kube.vsh-labs.cz/authorize',
  cloudMailAuthRedirectUrl: 'https://cloudmail.hu.dev.kube.vsh-labs.cz/authorize',
  release: version,
  enableCDNS: '1',
  enableCDN: '1',
  enableCloudMail: '1',
  forceDevWsProtocol: 'wss',
  mojeIdConnectRedirectUrl: 'https://client.hu.dev.kube.vsh-labs.cz/external-auth/mojeid/connect',
  mojeIdLoginRedirectUrl: 'https://client.hu.dev.kube.vsh-labs.cz/external-auth/mojeid/login',
  googleConnectRedirectUrl: 'https://client.hu.dev.kube.vsh-labs.cz/external-auth/google/connect',
  googleLoginRedirectUrl: 'https://client.hu.dev.kube.vsh-labs.cz/external-auth/google/login'
};
