function getServerUrl(type) {
    var typeUrl = type === 'managed'
        ? '/servers-managed'
        : '/servers-hosted';
    return function (id) { return [typeUrl, 'edit', id]; };
}
var ɵ0 = function (id) { return ['/team', 'edit', id]; }, ɵ1 = function (id) { return ['/server-park-access', 'edit', id]; }, ɵ2 = function (id) { return ['/tickets', 'detail', id]; }, ɵ3 = function (id) { return ['/invoices', 'detail', id]; };
var ENTITY_TRANSLATOR = {
    clientUser: ɵ0,
    clientPersonServerParkAccess: ɵ1,
    ticket: ɵ2,
    invoice: ɵ3,
    cloudManagedServer: getServerUrl('managed'),
    managedCluster: getServerUrl('managed'),
    managedServer: getServerUrl('managed'),
    m1ManagedCluster: getServerUrl('managed'),
    m1ManagedServer: getServerUrl('managed'),
    m2ManagedCluster: getServerUrl('managed'),
    m2ManagedServer: getServerUrl('managed'),
    windowsManagedCluster: getServerUrl('managed'),
    windowsManagedServer: getServerUrl('managed'),
    windowsManagedBasic: getServerUrl('managed'),
    windowsManagedCloud: getServerUrl('managed'),
    serverHosting: getServerUrl('hosted'),
    dedicatedServer: getServerUrl('hosted'),
    vds: getServerUrl('hosted'),
    domain: function (id) { return ['/domains', 'detail', id]; }
};
export function searchLinkGenerator(entity, id) {
    var entityLink = ENTITY_TRANSLATOR[entity];
    if (entityLink) {
        return entityLink(id);
    }
    else {
        console.warn('Non existant serch entity ', entity);
    }
}
export { ɵ0, ɵ1, ɵ2, ɵ3 };
